$(document).ready(function() {

    $(window).scroll(function() {
        // Set image parallax effect
        var scrollTop = $(window).scrollTop();
        var height = $(window).height();
        $('.lead-hero-content').css('background-position-y', 50 - (-scrollTop / 30) + "%");
        $('.lead-hero-content h1 .intro').css('margin-top', -scrollTop / 3);
        $('.lead-hero-content h1 .intro').css('opacity', 1 - (scrollTop / height));
    });

    // wrap iframe in video div
    $("iframe").wrap("<div class='embed-container'/>");

    // set fade on scroll animation
    $('.fade-in').fadeInScroll();

    // mobile nav toggle
    $(".nav-toggle").click(function() {
        $('.nav-list').slideToggle("fast", function() {});
    });

    $(window).resize(function() {
        var width = $(window).width();
        if (width > 1000) {
            $('.nav-list').show();
        }
    });

    // Change value to sentence case
    $("input.portal-feedback").bind('keyup', function(e) {
        var arr = $(this).val().split(' ');
        var result = "";
        for (var x = 0; x < arr.length; x++)
            result += arr[x].substring(0, 1).toUpperCase() + arr[x].substring(1) + ' ';
        $(this).val(result.substring(0, result.length - 1));
    });

    // get footer height on load
    function getFooterHeight() {
        var windowWidthOnLoad = $(window).width();
        var windowHeightOnLoad = $(window).height();
        var footer = $(".site-footer");
        var footerHeight = footer.innerHeight();
        var bodyClass = $('body').attr('class');
        if (windowWidthOnLoad >= 1000) {
            $('.site-content-wrapper').css('margin-bottom', footerHeight);
        }
        if (bodyClass == 'remove-fixed-footer') {
            $('.site-content-wrapper').css('margin-bottom', '0');
            footer.css('position', 'relative');
        }
        if (windowHeightOnLoad <= footerHeight && bodyClass != 'remove-fixed-footer') {
            $('.site-content-wrapper').css('margin-bottom', '0');
            footer.css('position', 'relative');
        }
    }
    getFooterHeight();
    $(window).resize(function() {
        getFooterHeight();
        var windowWidthOnResize = $(window).width();
        var windowHeightOnResize = $(window).height();
        var footer = $(".site-footer");
        var footerHeight = footer.innerHeight();
        var bodyClass = $('body').attr('class');
        if (windowWidthOnResize <= 1000) {
            $('.site-content-wrapper').css('margin-bottom', '0');
        }
        if (windowHeightOnResize >= footerHeight && bodyClass != 'remove-fixed-footer') {
            $('.site-content-wrapper').css('margin-bottom', footerHeight);
            footer.css('position', 'fixed');
            console.log("false");
        } else {
            $('.site-content-wrapper').css('margin-bottom', '0');
            footer.css('position', 'relative');
            console.log("true");
        }
    });

    // home carousel
    $('.home-carousel').flickity({
        cellSelector: '.home-cell',
        cellAlign: 'center',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
        autoPlay: true,
        autoPlay: 6000,
        pauseAutoPlayOnHover: true,
        draggable: false
    });

    // certificates carousel
    var $certificatesCarousel = $('.certificates-carousel').flickity({
        cellSelector: '.certificates-carousel-cell',
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        contain: true,
        setGallerySize: false
    });

    $('.certificates-carousel-prev').on('click', function() {
        $certificatesCarousel.flickity('previous');
    });

    $('.certificates-carousel-next').on('click', function() {
        $certificatesCarousel.flickity('next');
    });

    // testimonials carousel
    var $testimonialsCarousel = $('.testimonials-carousel').flickity({
        cellSelector: '.testimonials-cell',
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        //autoPlay: true,
        //autoPlay: 6000,
        pauseAutoPlayOnHover: true,
        setGallerySize: false
    });

    $('.testimonials-carousel-prev').on('click', function() {
        $testimonialsCarousel.flickity('previous');
    });

    $('.testimonials-carousel-next').on('click', function() {
        $testimonialsCarousel.flickity('next');
    });

    // accreditations carousel
    $('.accreditations-carousel').flickity({
        cellSelector: '.accreditations-cell',
        cellAlign: 'center',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        autoPlay: true,
        autoPlay: 5000,
        pauseAutoPlayOnHover: true,
        setGallerySize: false
    });

    // services list carousel
    var $servicesListCarousel = $('.services-carousel').flickity({
        cellSelector: '.services-carousel-cell',
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
    });

    $('.services-prev').on('click', function() {
        $servicesListCarousel.flickity('previous');
    });

    $('.services-next').on('click', function() {
        $servicesListCarousel.flickity('next');
    });

    // staff carousel
    var $staffCarousel = $('.staff-carousel').flickity({
        cellSelector: '.staff-carousel-cell',
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        //setGallerySize: false
    });

    $('.staff-prev').on('click', function() {
        $staffCarousel.flickity('previous');
    });

    $('.staff-next').on('click', function() {
        $staffCarousel.flickity('next');
    });

    // staff carousel
    var $staffPortalAnnouncementsCarousel = $('.staff-portal-announcements').flickity({
        cellSelector: '.announcements-carousel-cell',
        cellAlign: 'left',
        wrapAround: false,
        prevNextButtons: false,
        adaptiveHeight: true
    });

    $('.staff-prev').on('click', function() {
        $staffCarousel.flickity('previous');
    });

    $('.staff-next').on('click', function() {
        $staffCarousel.flickity('next');
    });

    // google maps
    initMap()

    function initMap() {

        var location = {
            lat: 54.843174,
            lng: -6.692725
        };
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 14,
            center: location,
            disableDefaultUI: true,
            scrollwheel: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
        });
        var marker = new google.maps.Marker({
            position: location,
            map: map
        });

        var center = map.getCenter();
        google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
        });
    }

});
